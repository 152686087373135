import React, {useEffect, useState} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import imageUrlFor from '@helpers/imageUrlFor';
import Faq from '@components/Faq';
import ContactBlock from '@components/ContactBlock';
import SEO from '@components/seo';
import FindRepresentative from '@src/account/FindRepresentative';

const ContactUs = ({ location }) => {
  const supportData = useStaticQuery(
    graphql`
      query {
        allSanitySupport {
          edges {
            node {
              metaDescription
              metaImage {
                asset {
                  _id
                }
              }
              title
            }
          }
        }
      }
    `,
  );

  const [contactInview, setContactInview] = useState(true)

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setContactInview(false)
      }
    } else {
      setContactInview(true)
    }
  }, []);

  const supportNode = supportData.allSanitySupport.edges[0].node;
  return (
    <div>
      <SEO
        title={supportNode?.title || null}
        description={
          supportNode?.metaDescription ? supportNode.metaDescription : null
        }
        image={
          supportNode?.metaImage?.asset
            ? imageUrlFor(supportNode.metaImage).url()
            : null
        }
      />
      <div className="">
        <ContactBlock showDealers inView={contactInview} bottomBorder={true}/>
      </div>

      <div className="mb-12 md:mb-30" id="findRep">
        <div className="wrapper">
          <div className="py-12 md:py-24">
            <FindRepresentative view="horizontal" />
          </div>
        </div>
      </div>
      
      <div className="my-12 md:my-30" id="faq">
        <Faq {...{ location }} />
      </div>
    </div>
  );
};

export default ContactUs;
