import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { useStaticQuery, graphql } from 'gatsby';
import AccordionInner from '@components/AccordionInner';
import PortableText from '@utility/PortableText';
import ScrollInView from '@utility/ScrollInView';

const Faq = ({ location }) => {
  const [propsLocation] = useState(location)
  const getTargetElement = ({ hash }) => {
    const id = getId({ hash })
    return document.getElementById(`${id}`)
  }

  const getId = ({ hash }) => `${hash.replace('#', '')}`

  const preExpand = [getId(propsLocation) || '']

  useEffect(() => {
    const scrollToTarget = (event) => {
      if (document.readyState === 'complete') {
        getTargetElement(propsLocation).scrollIntoView({ behavior: 'smooth' })
      }
    }

    if (location.hash !== '') {
      //document.addEventListener('readystatechange', scrollToTarget);
      window.addEventListener('load', scrollToTarget)
    }
    return () => {
      window.addEventListener('load', scrollToTarget)
      //document.removeEventListener('readystatechange', scrollToTarget)
    }
  }, [propsLocation])

  const faqData = useStaticQuery(
    graphql`
      query {
        allSanityFaq {
          edges {
            node {
              id
              heading
              _rawQuestions(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    `,
  );

  const questions = faqData.allSanityFaq.edges[0].node._rawQuestions;

  return (
    <ScrollInView>
      <div className="wrapper">
        <div className="row">
          <div className="col-12 md:col-8 lg:col-6 md:offset-1 xl:col-4">
            <h2 className="mb-12 xl:mb-0 type-sans-830 md:type-sans-930">
              {faqData.allSanityFaq.edges[0].node.heading}
            </h2>
          </div>

          <div className="col-12 md:offset-2 md:col-10 xl:col-6 xl:offset-0">
            <div className="md-down:negative-wrapper md:mx-0">
              <Accordion
                allowZeroExpanded
                preExpanded={preExpand}
                className="type-sans-230"
              >
                {questions &&
                  questions.map((question) => (
                    <AccordionInner
                      key={question._key}
                      label={question.question}
                      uuid={question._key}
                    >
                      <div className="richtext-content">
                        <PortableText blocks={question.answer.richText} />
                      </div>
                    </AccordionInner>
                  ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </ScrollInView >
  );
};

Faq.propTypes = {};

export default Faq;
