import React from 'react';
import PropTypes from 'prop-types';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import cn from 'classnames';

import * as styles from './styles';

const AccordionInner = ({
  uuid,
  children,
  label,
  print,
  wrapperModifier,
  wrapperClassName,
  dangerouslySetExpanded
}) => (
  <AccordionItem
    id={`${uuid}`}
    uuid={uuid}
    className={cn({ 'print:hidden': !print })}
    dangerouslySetExpanded={dangerouslySetExpanded}
  >
    <AccordionItemHeading className={styles.accordionHeading}>
      <AccordionItemButton className={styles.accordionButton}>
        {label}
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel className={styles.accordionPanel}>
      <div
        className={cn(styles.accordionWrapper, wrapperClassName)}
        style={wrapperModifier}
      >
        {children}
      </div>
    </AccordionItemPanel>
  </AccordionItem>
);

AccordionInner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  uuid: PropTypes.string,
  label: PropTypes.string.isRequired,
  print: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

AccordionInner.defaultProps = {
  print: true,
  wrapperClassName: '',
};

export default AccordionInner;
